import { geojsonToArcGIS as esriGeojsonToArcGIS } from '@esri/arcgis-to-geojson-utils';

const GEOJSON_ARCGIS_TYPES = {
  'Polygon': 'polygon',
  'MultiPolygon': 'polygon'
};

export function geojsonToArcGIS (geometry) {
  if (GEOJSON_ARCGIS_TYPES.hasOwnProperty(geometry.type)) {
    const arcgis = esriGeojsonToArcGIS(geometry);
    // wkid muss überschrieben werden, da die funktion annimmt, dass das geojson in 4326 vorliegt.
    arcgis.spatialReference.wkid = geometry.crs.properties.name.replace(/(EPSG:)/i, '');
    // type hinzufügen damit die arcgis fromJSON funktionen genutzt werden können
    arcgis.type = GEOJSON_ARCGIS_TYPES[geometry.type];
    return arcgis;
  } else {
    const error = 'Unsupported Geometry type.';
    console.error(error, geometry);
    throw new Error(error);
  }
}
